















































import { EventSession } from "@/models/event-session.model";
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

const eventSessionModule = namespace("EventSessions");
const languagesModule = namespace("Languages");

@Component({ components: { flatPickr } })
export default class EventSessionDetail extends Vue {
  isLoading = false;
  flatPickerConfig = {
    enableTime: true,
    dateFormat: "Y-m-d H:i",
  };

  @languagesModule.Getter("all")
  languages!: string[];

  @eventSessionModule.Getter("current")
  eventSession!: EventSession;

  @eventSessionModule.Action("fetchById")
  fetchEventSession!: (id: string) => Promise<EventSession>;

  @eventSessionModule.Action("create")
  create!: (eventSession: EventSession) => Promise<EventSession>;

  @eventSessionModule.Action("update")
  update!: (eventSession: EventSession) => Promise<EventSession>;

  get isNewEventSession() {
    return this.$route.name === "event-session-create";
  }

  async doSave() {
    this.isLoading = true;
    if (this.isNewEventSession) {
      await this.create(this.eventSession);
      this.$router.push({
        name: "event-sessions",
      });
    } else {
      await this.update(this.eventSession);
    }
    this.isLoading = false;
  }

  async doFetchEventSession() {
    this.isLoading = true;
    if (!this.isNewEventSession) {
      await this.fetchEventSession(this.$route.params.id);
    }
    this.isLoading = false;
  }

  created() {
    this.doFetchEventSession();
  }
}
